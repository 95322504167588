// import CSS
import styles from '../css/app.pcss';

//import exitIntent from 'exit-intent'
import Cookies from 'js-cookie'

// app main
const main = async () => {

    // Async load the vue module
    const { default: Vue } = await import(/* webpackChunkName: "vue" */ 'vue');

    // Create our vue instance
    const vm = new Vue({
        el: '#component-container',

        components: {
            'subscribe-takeover': () => import(/* webpackChunkName: "takeover" */ '@/vue/SubscribeTakeover.vue'),
            'mobile-menu'       : () => import(/* webpackChunkName: "mobilemenu" */ '@/vue/MobileMenu.vue'),
        },

        data: function() {
            return {
                subscribeModalPrepare: false,
                subscribeModalVisible: false
                // subscribeModalType: 'exitintent',
                // removeExitIntent: null
            };
        },

        methods: {
            handleScroll (event) {
                if( window.pageYOffset >= 120 ) {
                    document.body.classList.add("scrolled");
                }

                if( window.pageYOffset == 0 ) {
                    document.body.classList.remove("scrolled");
                }

                document.querySelector('div.loader').classList.remove('unload');
            },

            handleUnload( event ) {
                document.querySelector('div.loader').classList.add('unload');
            },

            handlePageShow( event ) {
                document.querySelector('div.loader').classList.remove('unload');
            },

            handleEscape() {
                this.subscribeModalPrepare = false
                this.subscribeModalVisible = false
            },

            handleSoftBlock() {
                if( this.subscribeModalPrepare ) {
                    this.subscribeModalVisible = true
                }
            },

            // handleExitIntent( event ) {
            //     if( this.subscribeModalPrepare ) {
            //         this.subscribeModalVisible = true
            //     }
            // },

            prepareSubscribeModal( force = false ) {

                const origialSource = Cookies.get('orgsrc') ?? false
                const seenTakeOver  = Cookies.get('seento') ?? false
                if( !force && ( origialSource == 'email' || origialSource == 'joined' || seenTakeOver ) ) { return }

                this.subscribeModalPrepare = ( window.showSubscribeModal ?? false )
                this.subscribeModalType    = window.showSubscribeModal ?? 'softblock'

                if( this.subscribeModalPrepare ) {
                    // if( this.subscribeModalType == 'exitintent' ) {
                    //     this.removeExitIntent = exitIntent({
                    //         threshold: 50,
                    //         maxDisplays: 1,
                    //         eventThrottle: 100,
                    //         onExitIntent: () => this.handleExitIntent()
                    //     })
                    // } else {
                        this.handleSoftBlock()
                    //}
                }
            }
        },

        mounted() {
            document.querySelector('div.loader').classList.remove('unload');

            const params = new Proxy(new URLSearchParams(window.location.search), {
                get: (searchParams, prop) => searchParams.get(prop),
            });

            const source   = (params.utm_source   ?? '').toLowerCase();
            const campaign = (params.utm_campaign ?? '').toLowerCase();
            const medium   = (params.utm_medium   ?? '').toLowerCase();
            const nlto     = params.newsletterSignup ?? false
            const force    = params.forceTakeover ?? false
            const words    = ["email", "newsletter"];

            if( force ) {
                this.prepareSubscribeModal(true)
            } else {
                if( words.includes( source ) || words.includes( medium ) || words.includes( campaign ) ) {
                    Cookies.set('orgsrc', 'email', { expires: 30 } )
                } else {
                    if( nlto ) {
                        Cookies.set('orgsrc', 'joined')
                    } else {
                        this.prepareSubscribeModal()
                    }
                }
            }
        },

        created () {
            document.body.classList.add("js-loaded");
            document.querySelector('div.loader').classList.add('active');
            document.querySelector('div.loader').classList.remove('unload');
            window.addEventListener('scroll', this.handleScroll);
            window.addEventListener('beforeunload', this.handleUnload);
            window.addEventListener('pageshow', this.handlePageShow);
        },

        destroyed () {
            window.removeEventListener('scroll', this.handleScroll);
            window.removeEventListener('beforeunload', this.handleUnload);
            window.removeEventListener('pageshow', this.handlePageShow);
        }
    })

    return vm;
};

// Execute async function
main().then( (vm) => {
    window.app = vm
});

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
    module.hot.accept();
}
